// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 300px;
}

.left-button,
.right-button {
  background-color: #6787C4;
  color: #ffffff;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: inherit;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.left-button:hover {
  background-color: #43577c;
}

.right-button:hover {
  background-color: #43577c;
}

.left-button img,
.right-button img {
  width: 200px;
  height: 200px;
}

.button-text-container {
  display: flex;
  justify-content: center;
  gap: 200px;
}

.button-text {
  text-align: center;
  white-space: pre-line;
  font-family: 'Roboto';
  font-size: 20px;
  padding-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,UAAU;AACZ;;AAEA;;EAEE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;;AAGA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/* src/App.css */\n.App {\n  text-align: center;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.button-container {\n  display: flex;\n  justify-content: center;\n  gap: 300px;\n}\n\n.left-button,\n.right-button {\n  background-color: #6787C4;\n  color: #ffffff;\n  width: 200px;\n  height: 200px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  font-size: inherit;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n\n.left-button:hover {\n  background-color: #43577c;\n}\n\n.right-button:hover {\n  background-color: #43577c;\n}\n\n.left-button img,\n.right-button img {\n  width: 200px;\n  height: 200px;\n}\n\n.button-text-container {\n  display: flex;\n  justify-content: center;\n  gap: 200px;\n}\n\n.button-text {\n  text-align: center;\n  white-space: pre-line;\n  font-family: 'Roboto';\n  font-size: 20px;\n  padding-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
