import React from 'react';
import './App.css';

function App() {
  const leftButtonUrl = process.env.REACT_APP_LEFT_BUTTON_URL || '/';
  const rightButtonUrl = process.env.REACT_APP_RIGHT_BUTTON_URL || '/';
  const leftButtonText = process.env.REACT_APP_LEFT_BUTTON_TEXT || 'Left button';
  const rightButtonText = process.env.REACT_APP_RIGHT_BUTTON_TEXT || 'Right button';
  const leftButtonDescription = process.env.REACT_APP_LEFT_BUTTON_DESCRIPTION || '';
  const rightButtonDescription = process.env.REACT_APP_RIGHT_BUTTON_DESCRIPTION || '';

  const handleLeftButtonClick = () => {
    window.location.href = leftButtonUrl;
  };

  const handleRightButtonClick = () => {
    window.location.href = rightButtonUrl;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="button-container">
          <button className="left-button" onClick={handleLeftButtonClick}>
            {leftButtonText}
          </button>
          <button className="right-button" onClick={handleRightButtonClick}>
            {rightButtonText}
          </button>
        </div>
        <div className="button-text-container">
          <div className="button-text">{leftButtonDescription.split("\\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
          <div className="button-text">{rightButtonDescription.split("\\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
        </div>
      </header>
    </div>
  );
}

export default App;
